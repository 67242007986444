<template>
  <div>
    <ReviewSystemFilter
      v-if="
        $can(
          'filter',
          'module_system_settings.settings.admin_subject.review_system'
        )
      "
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Review System" iconTable="MapIcon" />
      <ReviewSystemAdd
        :is-add-new-review-system-sidebar-active.sync="
          isAddNewReviewSystemSidebarActive
        "
        @createReviewSystem="createReviewSystem"
      />
      <ReviewSystemEdit
        v-if="isEditReviewSystemSidebarActive"
        :is-edit-review-system-sidebar-active.sync="
          isEditReviewSystemSidebarActive
        "
        :itemEdit="itemEdit"
        @editReviewSystemSuccess="editReviewSystemSuccess"
      />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>

          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                variant="primary"
                @click="isAddNewReviewSystemSidebarActive = true"
                :disabled="loading"
                v-if="
                  $can(
                    'store',
                    'module_system_settings.settings.admin_subject.review_system'
                  )
                "
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Review</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        outlined
        bordered
        hover
        class="position-relative"
        responsive
        :items="items"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-asc.sync="isSortDirDesc"
      >
        <template #cell(name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.name
            }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(category)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.category.name
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover.top="'Edit'"
            v-if="
              $can(
                'update',
                'module_system_settings.settings.admin_subject.review_system'
              )
            "
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="editReview(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-b-tooltip.hover.top="'Change Status'"
            v-if="
              $can(
                'update',
                'module_system_settings.settings.admin_subject.review_system'
              )
            "
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-warning"
            class="btn-icon"
            @click="changeReview(data.item)"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
          <b-button
            v-if="
              $can(
                'destroy',
                'module_system_settings.settings.admin_subject.review_system'
              )
            "
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            @click="deleteReview(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import ReviewSystemAdd from "./ReviewSystemAdd.vue";
import ReviewSystemEdit from "./ReviewSystemEdit.vue";
import ReviewSystemFilter from "./ReviewSystemFilter.vue";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import PaginationTable from "@/components/PaginationTable";

import axiosRS from "@/services/admin/consults/reviewSystems";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BTable,
    BCard,
    BFormInput,
    BCol,
    BRow,
    BButton,
    BPagination,
    vSelect,
    ToastNotification,
    ReviewSystemAdd,
    ReviewSystemEdit,
    ReviewSystemFilter,
    TitleTable,
    SpinnerLoading,
    GoBack,
    PaginationTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mounted() {
    this.getReviewSystem();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getReviewSystem();
      } else {
        this.searchFilter(this.reviewFilter);
      }
    },
  },
  data() {
    return {
      tableColumns: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "category", sortable: true },
        { key: "status", sortable: true },
        { key: "actions" },
      ],
      items: [],
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "id",
      isSortDirDesc: true,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      isAddNewReviewSystemSidebarActive: false,
      filtro: false,
      loading: false,
      citiesFilter: null,
      isEditReviewSystemSidebarActive: false,
      itemEdit: null,
      reviewFilter: null,
      filtro: false,
    };
  },
  methods: {
    getReviewSystem() {
      axiosRS
        .reviewSystemList(this.perPage)
        .then(({ data, total, current_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },

    pageChanged(page) {
      if (!this.filtro) {
        axiosRS
          .reviewSystemPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosRS
          .reviewSystemFilterPagination(this.perPage, page, this.reviewFilter)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    createReviewSystem(value) {
      if (value) {
        this.$refs.toast.success("Review System added successfully");
      } else {
        this.$refs.toast.danger("Review System not added");
      }
      this.isAddNewReviewSystemSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    editReview(item) {
      this.isEditReviewSystemSidebarActive = true;
      const data = {
        id: item.id,
        category_id: item.category_id,
        name: item.name,
        status: item.status,
      };
      this.itemEdit = ref(JSON.parse(JSON.stringify(data)));
    },
    editReviewSystemSuccess(value) {
      if (value) {
        this.$refs.toast.success("Review System updated successfully");
      } else {
        this.$refs.toast.danger("Review System not updated");
      }
      this.isEditReviewSystemSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    changeReview(item) {
      axiosRS
        .reviewSystemUpdate(item.id, { ...item, status: !item.status })
        .then(() => {
          this.$refs.toast.success("Review System changed successfully");
        })
        .catch(() => {
          this.$refs.toast.danger("Review System not changed");
        });
      this.pageChanged(this.currentPage.page);
    },
    deleteReview(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosRS.reviewSystemDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "Review System has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.reviewFilter = value;
        axiosRS
          .reviewSystemFilter(this.perPage, value)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getReviewSystem();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>
